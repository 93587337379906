// src/App.js
import React, { useState } from 'react';
import { ChakraProvider, Box } from '@chakra-ui/react';
import SignIn from './components/SignIn';
import ChatBot from './components/Chatbot';
import Profile from './components/Profile';
import Payment from './components/Payment';
import LandingPage from './components/LandingPage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import BirthDetails from './components/birthDetails';
import SignUp from './components/SignUp';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleSignInSuccess = () => {
    setIsAuthenticated(true);
  };

  return (
    <ChakraProvider>
      <Router>
        <Box p={4}>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/chat" element={isAuthenticated ? <ChatBot /> : <SignIn onSignInSuccess={handleSignInSuccess} />} />
            <Route path="/signin" element={<SignIn onSignInSuccess={handleSignInSuccess} />} />
            <Route path="/birthdetails" element={isAuthenticated ? <BirthDetails /> : <SignIn onSignInSuccess={handleSignInSuccess} />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/profile" element={isAuthenticated ? <Profile /> : <SignIn onSignInSuccess={handleSignInSuccess} />} />
            <Route path="/payment" element={isAuthenticated ? <Payment /> : <SignIn onSignInSuccess={handleSignInSuccess} />} />
          </Routes>
        </Box>
      </Router>
    </ChakraProvider>
  );
};

export default App;
