// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Reset some default styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: 'Philosopher', sans-serif;
  }
  
  /* Main container for the profile box */
  .profile-container {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 40px auto; /* Center horizontally and give some top margin */
    margin-top: 4.5rem;
    padding: 24px;
    border: 1px solid #e2e8f0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Avatar styling */
  .profile-avatar {
    border-radius: 50%;
  }
  
  /* Profile name styling */
  .profile-name {
    font-size: 24px;
    font-weight: bold;
    margin-top: 16px;
  }
  
  /* Profile email and subscription status styling */
  .profile-email,
  .profile-subscription {
    font-size: 16px;
    color: #555;
  }
  
  /* Loading message styling */
  .profile-loading {
    font-size: 18px;
    font-weight: 500;
    color: #999;
    text-align: center;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/profile.css"],"names":[],"mappings":"AAAA,8BAA8B;AAC9B;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;EACxB;;EAEA;IACE,sCAAsC;EACxC;;EAEA,uCAAuC;EACvC;IACE,uBAAuB;IACvB,kBAAkB;IAClB,wCAAwC;IACxC,gBAAgB;IAChB,iBAAiB,EAAE,iDAAiD;IACpE,kBAAkB;IAClB,aAAa;IACb,yBAAyB;IACzB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA,mBAAmB;EACnB;IACE,kBAAkB;EACpB;;EAEA,yBAAyB;EACzB;IACE,eAAe;IACf,iBAAiB;IACjB,gBAAgB;EAClB;;EAEA,kDAAkD;EAClD;;IAEE,eAAe;IACf,WAAW;EACb;;EAEA,4BAA4B;EAC5B;IACE,eAAe;IACf,gBAAgB;IAChB,WAAW;IACX,kBAAkB;EACpB","sourcesContent":["/* Reset some default styles */\n* {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n  }\n  \n  body {\n    font-family: 'Philosopher', sans-serif;\n  }\n  \n  /* Main container for the profile box */\n  .profile-container {\n    background-color: white;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    max-width: 600px;\n    margin: 40px auto; /* Center horizontally and give some top margin */\n    margin-top: 4.5rem;\n    padding: 24px;\n    border: 1px solid #e2e8f0;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n  \n  /* Avatar styling */\n  .profile-avatar {\n    border-radius: 50%;\n  }\n  \n  /* Profile name styling */\n  .profile-name {\n    font-size: 24px;\n    font-weight: bold;\n    margin-top: 16px;\n  }\n  \n  /* Profile email and subscription status styling */\n  .profile-email,\n  .profile-subscription {\n    font-size: 16px;\n    color: #555;\n  }\n  \n  /* Loading message styling */\n  .profile-loading {\n    font-size: 18px;\n    font-weight: 500;\n    color: #999;\n    text-align: center;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
