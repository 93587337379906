// src/components/CardGrid.js
import React from 'react';
import { Box, Image } from '@chakra-ui/react';
import '../styles/cardGrid.css'; // Assuming you're using a CSS file for styling

const CardGrid = ({ onCardClick }) => {
  const cardData = [
    { 
      title: 'Basic Prediction', 
      description: 'What are my basic predictions', 
      icon: 'https://img.icons8.com/?size=100&id=E9mVNY7X3oiJ&format=png&color=000000',
      topic: 'basic-predictions' 
    },
    { 
      title: 'Career', 
      description: 'How is My Career, Karma and Achievements?', 
      icon: 'https://img.icons8.com/?size=100&id=nRyu98q8EZ0G&format=png&color=000000',
      topic: "Career, Karma and Achievements (Dasamsa)"
    },
    { 
      title: 'Love Life', 
      description: 'What are the predictions for my love life and Marriage?', 
      icon: 'https://img.icons8.com/?size=100&id=gYkv3Lp0inE5&format=png&color=000000',
      topic: "Marriage, Spouse, Dharma, Skills (Navamsa)"
    },
    { 
      title: 'Health', 
      description: 'What astrological advice do you have for my health?', 
      icon: 'https://img.icons8.com/?size=100&id=Over2yiabsS2&format=png&color=000000',
      topic: "Health (Shashthamsa)"
    },
  ];

  return (
    <div style={{ textAlign: 'center' }}>
      <h1>Ask Me Anytime..</h1>
      <p>The Future in Your Hands - Artificial Intelligence Solutions that Deliver Innovation and Ease.</p>
      <Box className="card-container">
        {cardData.map((card, index) => (
          <Box
            key={index}
            className="card"
            onClick={() => onCardClick(card.description, card.topic)} // Pass both title and description
            textAlign="center" // Center the content of the box
            cursor="pointer" // Change cursor to pointer on hover
            _hover={{ boxShadow: 'lg' }} // Add hover effect
          >
            <Image 
              src={card.icon} 
              alt={card.title} 
              boxSize="50px" 
              mb={2} 
              mx="auto" // Center the image horizontally
            />
            <h3>{card.title}</h3>
            <p>{card.description}</p>
          </Box>
        ))}
      </Box>
    </div>
  );
};

export default CardGrid;
