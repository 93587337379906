// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Header.css - Styles for the header */

/* Header styling */
.chat-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding: 1rem;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    height: 80px;
    z-index: 10; /* Ensure it stays on top */
  }
  
  /* Title styling */
  .chat-title {
    font-size: 1.5rem;
    font-weight: bold;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/header.css"],"names":[],"mappings":"AAAA,uCAAuC;;AAEvC,mBAAmB;AACnB;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,8CAA8C;IAC9C,YAAY;IACZ,WAAW,EAAE,2BAA2B;EAC1C;;EAEA,kBAAkB;EAClB;IACE,iBAAiB;IACjB,iBAAiB;EACnB","sourcesContent":["/* Header.css - Styles for the header */\n\n/* Header styling */\n.chat-header {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    background-color: white;\n    padding: 1rem;\n    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */\n    height: 80px;\n    z-index: 10; /* Ensure it stays on top */\n  }\n  \n  /* Title styling */\n  .chat-title {\n    font-size: 1.5rem;\n    font-weight: bold;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
