// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full-screen height */
  }
  
  .rotating-image {
    width: 150px; /* Adjust size as needed */
    height: 150px;
    animation: rotate 4s linear infinite; /* 4 seconds rotation */
  }
  
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/loaderImage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa,EAAE,uBAAuB;EACxC;;EAEA;IACE,YAAY,EAAE,0BAA0B;IACxC,aAAa;IACb,oCAAoC,EAAE,uBAAuB;EAC/D;;EAEA;IACE;MACE,uBAAuB;IACzB;IACA;MACE,yBAAyB;IAC3B;EACF","sourcesContent":[".image-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh; /* Full-screen height */\n  }\n  \n  .rotating-image {\n    width: 150px; /* Adjust size as needed */\n    height: 150px;\n    animation: rotate 4s linear infinite; /* 4 seconds rotation */\n  }\n  \n  @keyframes rotate {\n    from {\n      transform: rotate(0deg);\n    }\n    to {\n      transform: rotate(360deg);\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
