// src/components/Header.js
import React, { useState, useEffect } from 'react';
import { 
  HStack, 
  Text, 
  Avatar, 
  Menu, 
  MenuButton, 
  MenuList, 
  MenuItem, 
  Button, 
  Image, 
  Select 
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { signOut, setPersistence, browserSessionPersistence } from 'firebase/auth';
import { auth } from './firebase';
import './styles/header.css';
import astroLogo from './assets/astrology-logo.png';
import { getChatLog, getTimer, updateTimer } from './firebaseFunctions';

const Header = ({ timerStart, page }) => {
  const [activeTab, setActiveTab] = useState('Chat');
  const [remainingTime, setRemainingTime] = useState('00:00'); // Initialize with the timer prop
  const navigate = useNavigate();
  const [guestUser, setGuestUser] = useState(true);

  console.log('activeTab:', activeTab);

  useEffect(() => {
    const fetchRemainingTime = async () => {
      try {
        const User = await getChatLog(auth.currentUser.uid);
          if(User){
            setGuestUser(false);
          }
          else{
            setGuestUser(true);
          }
        if(auth.currentUser && auth.currentUser?.emailVerified){
          console.log('current user:', auth.currentUser);
          const response = await getTimer(auth.currentUser.uid);
            console.log('Remaining time:', response['remaining_time']);
            const remaining_time = response['remaining_time']; // Extract the remaining time from the response
            console.log('Remaining time:', remaining_time);
            setRemainingTime(remaining_time); // Set the fetched time
        }
      } catch (error) {
        console.error('Error fetching timer:', error);
      }
    };

    fetchRemainingTime();
  }, []); // Empty dependency array ensures it runs only on mount

  const updateTimerOnStop = async (remaining_time, active_status) => {
    try {
      const response2 = updateTimer(auth.currentUser.uid, remaining_time, active_status);
      console.log('Timer updated:', response2);
    } catch (error) {
      console.error('Error updating timer:', error);
    }
  };

  useEffect(() => {
    let interval;

    if (remainingTime === '00:00' || remainingTime === '') {
      return;
    }

    const startTimer = () => {
      try{
        const [minutes, seconds] = remainingTime.split(':').map(Number); // Convert 'MM:SS' into minutes and seconds
        let totalSeconds = minutes * 60 + seconds;

        if (timerStart && totalSeconds > 0) {
          interval = setInterval(() => {
            totalSeconds -= 1;
            const mins = Math.floor(totalSeconds / 60);
            const secs = totalSeconds % 60;
            const timeString = `${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
            console.log('updating time', typeof timeString);
            setRemainingTime(timeString);

            if (totalSeconds === 0) {
              updateTimerOnStop('00:00', false);
              setRemainingTime('00:00');
              clearInterval(interval);
            }
          }, 1000);
        } else if (!timerStart) {
          if (totalSeconds <= 0) {
            updateTimerOnStop('00:00', false);
            setRemainingTime('00:00');
          } else {
            updateTimerOnStop(remainingTime, true);
          }
        }
      } catch (error) {
        console.error('Error starting timer:', error);
        return;
      }
    };

    startTimer();

    return () => clearInterval(interval);
  }, [timerStart, remainingTime]);

  const handleLogout = () => {
    setPersistence(auth, browserSessionPersistence).then(() => {
      signOut(auth)
        .then(() => {
          console.log('User signed out and session cleared.');
          navigate('/');
        })
        .catch((error) => {
          console.error('Sign-out error:', error);
        });
    });
    setGuestUser(true);
  };

  const handleTabSwitch = (tabName, route) => {
    setActiveTab(tabName); // Set the active tab before navigating
    navigate(route); // Navigate to the route
  };

  return (
    <HStack className="chat-header" bg="white" padding="20px">
      <Image 
        src={astroLogo} 
        style={{ cursor: 'pointer', width: '200px' }} 
        onClick={() => navigate('/')}
        boxSize="50px"
      />

      {!guestUser && (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {/* Conditionally render the Select only on the Chat page */}
          {/* {page === 'chat' && (
            <Select 
              placeholder="Select a topic" 
              value={selectedTopic} 
              onChange={(e) => setSelectedTopic(e.target.value)}
              width="500px"
              marginLeft="20px"
            >
              <option value="" disabled>Select a topic</option>
              {[
                "Wealth, Resources and Money (Hora)",
                "Environment related to Brothers and Sisters (Drekkana)",
                "Residence, Houses, Properties and Fortune (Chaturthamsa)",
                "Fame, Authority and Power (Panchamsa)",
                "Health (Shashthamsa)",
                "Environment related to children (Saptamsa)",
                "Legal Issues, Sudden and Unexpected troubles (Ashtamsa)",
                "Marriage, Spouse, Dharma, Skills (Navamsa)",
                "Career, Karma and Achievements (Dasamsa)",
                "Death and Destruction (Rudramsa)",
                "Environment related to parents (Dwadasamsa)",
                "Vehicle Related Pleasures (Shodasamsa)",
                "Religious and spiritual matters (Vimsamsa)",
                "Learning, Knowledge and Education (Chaturvimsamsa)",
                "Strengths and Weaknesses and inherent nature (Nakshatramsa)",
                "Evils and punishment, sub-conscious self, Diseases (Trimsamsa)",
                "Auspicious and Inauspicious events (Khavedamsa)",
                "General Issues (Akshavedamsa)",
                "Balance sheet of past lives (Shashtyamsa)"
              ].map((topic) => (
                <option key={topic} value={topic}>
                  {topic}
                </option>
              ))}
            </Select>
          )} */}

          {/* Display the timer */}
          <Text
            fontWeight="bold"
            color="red.500"
            marginLeft={page === 'chat' ? '20px' : '0'}
          >
            {remainingTime} {/* Display the timer here */}
          </Text>
        </div>
      )}

      {/* Navigation and Profile Menu */}
      {!guestUser && (
        <Menu>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />} variant="ghost">
            <Avatar size="sm" name="User" src={auth.currentUser?.photoURL} />
          </MenuButton>
          <MenuList>
            {/* Move navigation links into the Menu */}
            <MenuItem onClick={() => handleTabSwitch('Chat', '/chat')}>Chat</MenuItem>
            <MenuItem onClick={() => handleTabSwitch('Profile', '/profile')}>Profile</MenuItem>
            <MenuItem onClick={() => handleTabSwitch('Payment', '/payment')}>Payment</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </MenuList>
        </Menu>
      )}

      {/* Sign Up and Sign In for Guest Users */}
      {guestUser && page === 'landing' && (
        <div>
          {/* <Button 
            onClick={() => navigate('/signup')} 
            style={{ 
              color: 'black', 
              backgroundColor: 'white', 
              borderRadius: '20px', 
              border: '1px solid black', 
              marginRight: '2.5px' 
            }} 
          >
            Sign up
          </Button> */}
          <Button 
            style={{
              color: 'white',
              backgroundColor: 'black',
              borderRadius: '20px',
              marginLeft: '2.5px',
              transition: 'background-color 0.3s, color 0.3s', // Add smooth transition for hover effect
            }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = 'white';
              e.target.style.color = 'black';
              e.target.style.border = '1px solid black';
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = 'black';
              e.target.style.color = 'white';
              e.target.style.border = 'none';
            }}
            onClick={() => navigate('/signin')}
          >
            Sign in
          </Button>

        </div>
      )}
    </HStack>
  );
};

export default Header;
