import React, { useEffect, useState } from 'react';
import { Button, Input, Box, Text, useToast } from '@chakra-ui/react';
import { auth } from '../firebase';
import Header from '../header';
import '../styles/payment.css'; // Import the CSS file
import { useNavigate } from 'react-router-dom';
import bgImage from '../assets/03.svg';
import { updatePayment } from '../firebaseFunctions';

const Payment = () => {
  const [amount, setAmount] = useState('');
  const email = auth.currentUser.email;
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/payment');
    // eslint-disable-next-line
  }, []);

  const handlePayment = () => {
    const options = {
      key: 'rzp_test_qykweQJxBjXsdC', // Enter your Razorpay key ID here
      amount: amount * 100, // Amount is in paise, so multiply by 100
      currency: 'INR',
      name: 'Your Company Name',
      description: 'Test Transaction',
      image: 'https://your-logo-url.com/logo.png', // Optional
      handler: async (response) => {
        toast({
            title: 'Success',
            description: 'Payment successful with payment id: ' + response.razorpay_payment_id,
            status: 'success',
            duration: 5000,
            isClosable: true,
        });
        const paymentData = updatePayment(auth.currentUser.uid, response.razorpay_payment_id);
        paymentData.then((result) => {
          toast({
            title: 'Success',
            description: result.message,
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
        })
        .catch((error) => {
          console.error('Error updating payment:', error);
          toast({
            title: 'Error',
            description: error.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        });
      },
      prefill: {
        name: email, // Optional
        email: email, // Optional
        contact: '', // Optional
      },
      notes: {
        address: 'Customer Address', // Optional
      },
      theme: {
        color: '#F37254', // Customize the color
      },
    };

    const razorpay = new window.Razorpay(options);
    razorpay.open();
  };

  return (
    <div style={{ backgroundImage: `url(${bgImage})`, backgroundPosition: "center", height: '80vh' }}>
      <Header />
      <Box className="payment-container">
        <Text className="payment-title">Make a Payment</Text>
        <Input
          type="number"
          placeholder="Enter amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          className="payment-input"
        />
        <Button className="payment-button" onClick={handlePayment} isDisabled={!amount}>
          Pay Now
        </Button>
      </Box>
    </div>
  );
};

export default Payment;
