// src/components/LandingPage.js
import React from 'react';
import { Box, Heading, Text, Button } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import Header from '../header';
import '../App.css';
// import bg.jpg as bgImg
import bgImg from '../assets/bg.jpg';
import { useNavigate } from 'react-router-dom';

const LandingPage = () => {

    const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate('/signin');
    console.log("Get Started clicked!");
  };

  return (
    <div>
      <Header page='landing'/>
      <Box
        minH={'90vh'}
        bgImage={`url(${bgImg})`} // Update with your image path
        bgSize="cover"
        bgPosition="center"
        display="flex" // Use flexbox for centering
        alignItems="center" // Center vertically
      >
        <Box width="full" maxWidth="800px" textAlign="left" marginLeft='11%'> {/* Center text */}
          <Heading as="h1" size="2xl" mb={4} fontFamily="'Philosopher', sans-serif">
            Welcome to Our Platform
          </Heading>
          <Text fontSize="lg" mb={6}>
            Your one-stop solution for all your needs. Get started with us today!
          </Text>
          <Text>
            Get Started 
          <Button colorScheme='black' onClick={handleGetStarted} variant='outline' borderRadius="50%" height='45px' marginLeft='10px'>
            <ArrowForwardIcon/>
          </Button>
          </Text>
        </Box>
      </Box>
    </div>
  );
};

export default LandingPage;
