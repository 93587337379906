import React, { useEffect, useState } from 'react';
import { Box, Button, Input, VStack, Text, useToast, Image, Icon, Spinner } from '@chakra-ui/react';
import { GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup, RecaptchaVerifier, signInWithPhoneNumber, setPersistence, browserLocalPersistence, onAuthStateChanged } from 'firebase/auth';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import googleIcon from '../assets/icons/google.png';
import { doc, getDoc } from 'firebase/firestore';
import Header from '../header';
import bgImage from '../assets/03.svg'
import { auth, db } from '../firebase';
import { createUser, getBirthDetails, getRasiandNavamsa } from '../firebaseFunctions';

const SignIn = ({ onSignInSuccess }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [initialLoading, setInitialLoading] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [isPhoneSignIn, setIsPhoneSignIn] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();


  useEffect(() => {
    setInitialLoading(true);
    navigate('/signin');
    setInitialLoading(false);
    // eslint-disable-next-line
  }, []);

  // Restore session
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setInitialLoading(true); // Start loading
      if (user && user.emailVerified) {
        console.log('Session restored:', user);
        onSignInSuccess();
  
        // Fetch birth details and navigate accordingly
        getBirthDetails(auth.currentUser.uid).then((result) => {
          if (Object.keys(result).length !== 0) {
            getRasiandNavamsa(auth.currentUser.uid).then((data) => {
              if (Object.keys(data['rasi_chart']).length !== 0 && Object.keys(data['navamsa_chart']).length !== 0) {
                console.log('User created successfully:', data);
                navigate('/chat'); // Redirect to home page
              } else {
                navigate('/birthdetails');
              }
              setInitialLoading(false); // Stop loading after navigating
            }).catch((error) => {
              console.log('Rasi/Navamsa data fetch error:', error);
              navigate('/birthdetails'); // Redirect to home page
              setInitialLoading(false); // Stop loading in case of error
            });
          } else {
            navigate('/birthdetails');
            setInitialLoading(false); // Stop loading if no birth details found
          }
        }).catch((error) => {
          console.error('Error during sign-in:', error.message);
          setInitialLoading(false); // Stop loading on error
        });
      } else {
        console.log('No session found.');
        setInitialLoading(false); // Stop loading if no user
      }
    });
  
    return () => unsubscribe(); // Cleanup subscription on unmount
  }, [onSignInSuccess]);
  
  // Email sign-in function
  const handleEmailSignIn = async () => {
    setInitialLoading(true); // Start loading
    try {
      await setPersistence(auth, browserLocalPersistence);
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      if (!userCredential.user.emailVerified) {
        toast({
          title: 'Error',
          description: 'Email not verified. Verify your email to sign in.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setInitialLoading(false); // Stop loading if email not verified
      } else {
        const response1 = createUser(auth.currentUser.uid, auth.currentUser);
        response1.then(() => {
          console.log('Signed in successfully:', userCredential.user);
          onSignInSuccess();
          navigate('/birthdetails'); // Redirect to birth details page
          setInitialLoading(false); // Stop loading after navigating
        }).catch((error) => {
          console.error('Error during sign-in:', error.message);
          toast({
            title: 'Error',
            description: error.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          setInitialLoading(false); // Stop loading on error
        });
      }
    } catch (error) {
      console.error('Error during sign-in:', error.message);
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setInitialLoading(false); // Stop loading on error
    }
  };
  

  // Google sign-in
  const handleGoogleSignIn = async () => {
    
    const provider = new GoogleAuthProvider();
    try {
      setInitialLoading(true);
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Check if the user exists in Firestore
      const userRef = doc(db, 'users', user.uid);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        
      toast({
        title: 'Success',
        description: "User signed in successfully.",
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      
      onSignInSuccess();
      navigate('/birthdetails'); // Redirect to home page
    }
    else {
      toast({
        title: 'Error',
        description: "User not signed up. Please sign up",
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
    setInitialLoading(false);
    } catch (error) {
      console.error('Error during Google sign-in:', error.message);
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setInitialLoading(false);
    }
  };

  // Phone sign-in
  const handlePhoneSignIn = async () => {
    const appVerifier = new RecaptchaVerifier(auth, 'recaptcha-container');
    try {
      const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, appVerifier);
      window.confirmationResult = confirmationResult; // Store confirmationResult for later
      console.log('Verification code sent to your phone.');
    } catch (error) {
      console.error('Error during phone sign-in:', error.message);
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Verify phone number
  const handleVerifyCode = async () => {
    setInitialLoading(true);
    const confirmationResult = window.confirmationResult;
    try {
      await confirmationResult.confirm(verificationCode);
      console.log('Signed in successfully with phone number.');
      onSignInSuccess();
    } catch (error) {
      console.error('Error during verification:', error.message);
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    setInitialLoading(false);
  };

  return (
    <div style={{ backgroundImage: `url(${bgImage})`, backgroundPosition: "center", height: '80vh' }}>
      <Header page='Sign Up'/>
      {!initialLoading ? (<Box p={4} bg="white" borderRadius="md" boxShadow="md" maxWidth="400px" mx="auto" mt={10} style={{ marginTop: '3rem' }}>
      {isPhoneSignIn ? (
        <VStack spacing={4} align="stretch">
          <Text fontSize="2xl" fontWeight="bold" textAlign="center">Phone Sign In</Text>
          <PhoneInput country={'in'} value={phoneNumber} onChange={(phoneNumber) => setPhoneNumber("+" + phoneNumber)} bg="gray.100" />
          <Button onClick={handlePhoneSignIn} colorScheme="blue">Send Verification Code</Button>
          <Input type="text" placeholder="Verification Code" value={verificationCode} onChange={(e) => setVerificationCode(e.target.value)} variant="filled" bg="gray.100" />
          <Button onClick={handleVerifyCode} colorScheme="green">Verify Code</Button>
          <div id="recaptcha-container"></div>
          <Button variant="link" onClick={() => setIsPhoneSignIn(false)}>Back to Email Sign In</Button>
        </VStack>
      ) : (
        <VStack spacing={4} align="stretch">
          <Text fontSize="2xl" fontWeight="bold" textAlign="center">Sign In</Text>
          <Input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} variant="filled" bg="gray.100" />
          <Input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} variant="filled" bg="gray.100" />
          <Button onClick={handleEmailSignIn} style={{ color:'white', backgroundColor: 'black', borderRadius:' 20px' }}>Sign In with Email</Button>
          <Text textAlign="center">OR</Text>
          <Button onClick={handleGoogleSignIn} style={{ color:'black', backgroundColor: 'white', border: '1px solid black', borderRadius: '20px' }}>
          <Image src={googleIcon} alt="phone" width="20px" height="20px" marginRight='20px' />
            Continue with Google</Button>
          <Text textAlign="center">Don't have an account?{' '}
            <Button variant="link" onClick={() => navigate('/signup')}>Sign Up</Button>
          </Text>
          <Text textAlign="center">Want to sign in with phone?{' '}
            <Button variant="link" onClick={() => setIsPhoneSignIn(true)}>
              Sign In with Phone</Button>
          </Text>
        </VStack>
      )}
      </Box>) :
      (
        <Box p={6} bg="white" borderRadius="md" boxShadow="md" maxWidth="400px" mx="auto" mt={10} style={{ marginTop: '3rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Spinner size="md" color='#C84647' style={{ marginRight: '10px' }} />
          <Text fontSize="2xl" fontWeight="bold" textAlign="center">
            Signing you in...
          </Text>
        </Box>
      )}
    </div>
  );
};

export default SignIn;
