// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    font-family: 'Philosopher', sans-serif;
    background-color: #f8f9fa;
    margin: 0;
    padding: 0;
  }
  
  .container {
    text-align: center;
    /* padding: 50px; */
  }
  
  h1 {
    font-size: 2.5rem;
    color: #333;
  }
  
  p {
    font-size: 1.2rem;
    color: #666;
  }
  
  .card-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    max-width: 800px;
    margin: 30px auto;
  }
  
  .card {
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease;
  }
  
  .card:hover {
    transform: translateY(-10px);
  }
  
  .icon {
    font-size: 2.5rem;
  }
  
  h3 {
    font-size: 1.5rem;
    color: #333;
    margin: 10px 0;
  }
  
  p {
    font-size: 1rem;
    color: #666;
  }

  .card:nth-child(1),
.card:nth-child(4) {
  background-color: #e1eeef; /* Light purple */
}

.card:nth-child(2),
.card:nth-child(3) {
  background-color: #f6e8fd; /* Light blue */
}
  `, "",{"version":3,"sources":["webpack://./src/styles/cardGrid.css"],"names":[],"mappings":"AAAA;IACI,sCAAsC;IACtC,yBAAyB;IACzB,SAAS;IACT,UAAU;EACZ;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,WAAW;EACb;;EAEA;IACE,iBAAiB;IACjB,WAAW;EACb;;EAEA;IACE,aAAa;IACb,qCAAqC;IACrC,SAAS;IACT,gBAAgB;IAChB,iBAAiB;EACnB;;EAEA;IACE,mBAAmB;IACnB,aAAa;IACb,yCAAyC;IACzC,kBAAkB;IAClB,+BAA+B;EACjC;;EAEA;IACE,4BAA4B;EAC9B;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,iBAAiB;IACjB,WAAW;IACX,cAAc;EAChB;;EAEA;IACE,eAAe;IACf,WAAW;EACb;;EAEA;;EAEA,yBAAyB,EAAE,iBAAiB;AAC9C;;AAEA;;EAEE,yBAAyB,EAAE,eAAe;AAC5C","sourcesContent":["body {\n    font-family: 'Philosopher', sans-serif;\n    background-color: #f8f9fa;\n    margin: 0;\n    padding: 0;\n  }\n  \n  .container {\n    text-align: center;\n    /* padding: 50px; */\n  }\n  \n  h1 {\n    font-size: 2.5rem;\n    color: #333;\n  }\n  \n  p {\n    font-size: 1.2rem;\n    color: #666;\n  }\n  \n  .card-container {\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    gap: 20px;\n    max-width: 800px;\n    margin: 30px auto;\n  }\n  \n  .card {\n    border-radius: 15px;\n    padding: 20px;\n    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);\n    text-align: center;\n    transition: transform 0.3s ease;\n  }\n  \n  .card:hover {\n    transform: translateY(-10px);\n  }\n  \n  .icon {\n    font-size: 2.5rem;\n  }\n  \n  h3 {\n    font-size: 1.5rem;\n    color: #333;\n    margin: 10px 0;\n  }\n  \n  p {\n    font-size: 1rem;\n    color: #666;\n  }\n\n  .card:nth-child(1),\n.card:nth-child(4) {\n  background-color: #e1eeef; /* Light purple */\n}\n\n.card:nth-child(2),\n.card:nth-child(3) {\n  background-color: #f6e8fd; /* Light blue */\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
