// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* App.css - Global styles */

/* Reset some default styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Philosopher', sans-serif !important;
}

/* Specify font for all headings */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Philosopher', sans-serif;
  font-weight: bold; /* Ensure headings are bold */
}

/* Paragraph and other text elements */
p, .text {
  font-family: 'Philosopher', sans-serif; /* Apply the font to text */
}

/* Full page height for layouts */
.chat-container-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full screen height */
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,4BAA4B;;AAE5B,8BAA8B;AAC9B;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE,iDAAiD;AACnD;;AAEA,kCAAkC;AAClC;EACE,sCAAsC;EACtC,iBAAiB,EAAE,6BAA6B;AAClD;;AAEA,sCAAsC;AACtC;EACE,sCAAsC,EAAE,2BAA2B;AACrE;;AAEA,iCAAiC;AACjC;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa,EAAE,uBAAuB;AACxC","sourcesContent":["/* App.css - Global styles */\n\n/* Reset some default styles */\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\nbody {\n  font-family: 'Philosopher', sans-serif !important;\n}\n\n/* Specify font for all headings */\nh1, h2, h3, h4, h5, h6 {\n  font-family: 'Philosopher', sans-serif;\n  font-weight: bold; /* Ensure headings are bold */\n}\n\n/* Paragraph and other text elements */\np, .text {\n  font-family: 'Philosopher', sans-serif; /* Apply the font to text */\n}\n\n/* Full page height for layouts */\n.chat-container-wrapper {\n  display: flex;\n  flex-direction: column;\n  height: 100vh; /* Full screen height */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
