// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-bubble {
    background-color: white;
    padding: 0.75rem;
    border: 1px solid black;
    border-radius: 2.5rem;
    border-top-left-radius: 0.5rem;
    display: inline-block;
  }
  
  .typing {
    align-items: center;
    display: flex;
    height: 17px;
  }
  
  .typing .dot {
    animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
    background-color: #6CAD96;
    border-radius: 50%;
    height: 7px;
    margin-right: 4px;
    vertical-align: middle;
    width: 7px;
    display: inline-block;
  }
  
  .typing .dot:nth-child(1) {
    animation-delay: 200ms;
  }
  
  .typing .dot:nth-child(2) {
    animation-delay: 300ms;
  }
  
  .typing .dot:nth-child(3) {
    animation-delay: 400ms;
  }
  
  .typing .dot:last-child {
    margin-right: 0;
  }
  
  @keyframes mercuryTypingAnimation {
    0% {
      transform: translateY(0px);
      background-color: #6CAD96;
    }
    28% {
      transform: translateY(-7px);
      background-color: #9ECAB9;
    }
    44% {
      transform: translateY(0px);
      background-color: #B5D9CB;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/chatBubble.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,gBAAgB;IAChB,uBAAuB;IACvB,qBAAqB;IACrB,8BAA8B;IAC9B,qBAAqB;EACvB;;EAEA;IACE,mBAAmB;IACnB,aAAa;IACb,YAAY;EACd;;EAEA;IACE,2DAA2D;IAC3D,yBAAyB;IACzB,kBAAkB;IAClB,WAAW;IACX,iBAAiB;IACjB,sBAAsB;IACtB,UAAU;IACV,qBAAqB;EACvB;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE;MACE,0BAA0B;MAC1B,yBAAyB;IAC3B;IACA;MACE,2BAA2B;MAC3B,yBAAyB;IAC3B;IACA;MACE,0BAA0B;MAC1B,yBAAyB;IAC3B;EACF","sourcesContent":[".chat-bubble {\n    background-color: white;\n    padding: 0.75rem;\n    border: 1px solid black;\n    border-radius: 2.5rem;\n    border-top-left-radius: 0.5rem;\n    display: inline-block;\n  }\n  \n  .typing {\n    align-items: center;\n    display: flex;\n    height: 17px;\n  }\n  \n  .typing .dot {\n    animation: mercuryTypingAnimation 1.8s infinite ease-in-out;\n    background-color: #6CAD96;\n    border-radius: 50%;\n    height: 7px;\n    margin-right: 4px;\n    vertical-align: middle;\n    width: 7px;\n    display: inline-block;\n  }\n  \n  .typing .dot:nth-child(1) {\n    animation-delay: 200ms;\n  }\n  \n  .typing .dot:nth-child(2) {\n    animation-delay: 300ms;\n  }\n  \n  .typing .dot:nth-child(3) {\n    animation-delay: 400ms;\n  }\n  \n  .typing .dot:last-child {\n    margin-right: 0;\n  }\n  \n  @keyframes mercuryTypingAnimation {\n    0% {\n      transform: translateY(0px);\n      background-color: #6CAD96;\n    }\n    28% {\n      transform: translateY(-7px);\n      background-color: #9ECAB9;\n    }\n    44% {\n      transform: translateY(0px);\n      background-color: #B5D9CB;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
