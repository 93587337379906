// src/components/CitySelect.js
import React, { useState } from 'react';
import Select from 'react-select';
import axios from 'axios';

const CitySelect = ({ onCitySelect }) => {
  const [options, setOptions] = useState([]);


  // Function to fetch cities based on user input
  const fetchCities = async (inputValue) => {
    if (!inputValue || inputValue.length < 3) {
      setOptions([]);
      return;
    }

    try {
      const response = await axios.get('https://wft-geo-db.p.rapidapi.com/v1/geo/cities', {
        params: {
          namePrefix: inputValue,
          sort: '-population',
          limit: 10,
        },
        headers: {
          'X-RapidAPI-Key': process.env.REACT_APP_GEODB_API_KEY,
          'X-RapidAPI-Host': process.env.REACT_APP_GEODB_API_HOST,
        },
      });

      const cities = response.data.data;

      const formattedOptions = cities.map((city) => ({
        value: city.id,
        label: `${city.name}, ${city.countryCode}`,
        latitude: city.latitude,
        longitude: city.longitude,
      }));

      setOptions(formattedOptions);
    } catch (error) {
      console.error('Error fetching cities:', error);
      setOptions([]); // Reset options on error
    }
  };

  // Handler for input changes in the search box
  const handleInputChange = (inputValue) => {
    fetchCities(inputValue);
  };

  // Handler for when a city is selected
  const handleChange = (selectedOption) => {
    if (selectedOption) {
      onCitySelect({
        name: selectedOption.label,
        latitude: selectedOption.latitude,
        longitude: selectedOption.longitude,
      });
    } else {
      onCitySelect(null);
    }
  };

  return (
    <Select
      placeholder="Search for a city..."
      onInputChange={handleInputChange}
      onChange={handleChange}
      options={options}
      isClearable
      isSearchable
    />
  );
};

export default CitySelect;
